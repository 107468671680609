import * as React from "react"
import Layout from "../../components/layout"
import Subscribe from "../../components/subscribe"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const partnershipPage = () => (
    <Layout siteTitle={"WiN Partnerships"}>

    {/* top banner */}
    <div className="relative bg-indigo-800">
      <div className="absolute inset-0">
        <StaticImage
          className="h-full w-full object-bottom object-cover"
          src="../../images/PartnershipsBanner.jpg"
          alt=""
        />
        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Learn About our Partners</h1>
        <p className="mt-6 font-light max-w-3xl text-xl text-indigo-100">
        Our partners generously help WiN achieve its mission through access to resources and funding, research facilities and faculty support, as well as opportunities for collaboration and shared learning
        </p>
      </div>
    </div>

    {/* set divider for partnership boxes */}
    <div class="divide-y divide-double">

    {/* partnerships 1*/}
    <div className="bg-indigo-700">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-20 lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-indigo-900">
        WiN partners with the following organizations to offer paid, 8-week internships for female students to work with their scientists and build the confidence and leadership skills they need to realize their career goals:
        <ul className="list-disc pl-8 ml-4">
          <li><a class="styledLink" href="https://neuroscience.utexas.edu/">UT Department of Neuroscience</a></li>
          <li><a class="styledLink" href="https://dellmed.utexas.edu/">The Dell Medical School Department of Neurology</a></li>
        </ul>

        </h2>
        {/* <div className="mt-8 flow-root lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <a href="https://htu.edu/">
                <img
                  className="h-20"
                  src="https://www.forkliftdanceworks.org/wp-content/uploads/2020/02/output-onlinepngtools-8-600x440.png"
                  alt="StaticKit"
                />
              </a>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <a href="https://cns.utexas.edu/">
                <img
                  className="h-12"
                  src="https://cns.utexas.edu/templates/CNS_3/images/cns_header_Natural_Sciences.png"
                  alt="Transistor"
                />
              </a>  
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <a href="https://www.annrichardsschool.org/">
                <img
                  className="h-12"
                  src="https://www.annrichardsschool.org/wp-content/themes/joints/library/images/ars-logo_2x.png"
                  alt="Workcation"
                />
              </a>  
            </div>
          </div>
        </div> */}
      </div>
    </div>

    {/* partnerships 2*/}
    <div className="bg-gradient-to-b from-indigo-700 to-white">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-20 lg:px-8">
      <h2 className="text-2xl font-bold tracking-tight text-indigo-900">
        WiN also partners with the following organizations to recruit high-performing high school seniors and undergraduates to spend the summer as WiNterns:
        <ul className="list-disc pl-8 ml-4">
          <li><a className="styledLink" href="https://www.annrichardsschool.org/">Ann Richards School for Young Women Leaders</a></li>
          <li><a className="styledLink" href="https://breakthroughctx.org/">Breakthrough Central Texas</a></li>
          <li><a className="styledLink" href="https://htu.edu/">Huston-Tillotson University</a></li>
          <li><a className="styledLink" href="https://cns.utexas.edu/">UT Austin College of Natural Sciences</a></li>
          <li><a className="styledLink" href="https://www.utsa.edu/">University of Texas at San Antonio</a></li>
        </ul>
      </h2>
        {/* <div className="mt-8 flow-root lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <a href="https://htu.edu/">
                <img
                  className="h-20"
                  src="https://www.forkliftdanceworks.org/wp-content/uploads/2020/02/output-onlinepngtools-8-600x440.png"
                  alt="StaticKit"
                />
              </a>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <a href="https://cns.utexas.edu/">
                <img
                  className="h-12"
                  src="https://cns.utexas.edu/templates/CNS_3/images/cns_header_Natural_Sciences.png"
                  alt="Transistor"
                />
              </a>  
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <a href="https://www.annrichardsschool.org/">
                <img
                  className="h-12"
                  src="https://www.annrichardsschool.org/wp-content/themes/joints/library/images/ars-logo_2x.png"
                  alt="Workcation"
                />
              </a>  
            </div>
          </div>
        </div> */}
      </div>
    </div>

    </div>

    {/* email subscribe */}
    <Subscribe />

  </Layout>
)

export const Head = () => <Seo title="WiN Partnerships" />

export default partnershipPage